/* eslint-disable react/no-danger */

import observer from 'saddlebag-observer';

import {
  BpkAccordionItem,
  withAccordionItemState,
} from '@skyscanner/backpack-web/bpk-component-accordion';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import constants, {
  ACTION_TYPE,
  CAR_HIRE_SEO_EVENT_COMPONENT,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  namespace,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import { setCarHireSeoEventValue } from '../../../sessionStorage';

import type { TrackingContextProps } from 'common-types/types/TrackingContextProps';

import STYLES from './QuestionAndAnswer.module.scss';

const StatefulAccordionItem = withAccordionItemState(BpkAccordionItem);

type QuestionAndAnswerProps = {
  id: string;
  category: string;
  question: string;
  answer: string;
  expanded?: boolean;
  onClick?: void;
  currency: string;
  deviceType: string;
  locale: string;
  market: string;
  pageType: string;
};

const extendedOnClick = (
  initialOnClick: any,
  id: string,
  question: string,
  answer: string,
  category: string,
  { currency, deviceType, locale, market, pageType }: TrackingContextProps,
) => {
  if (typeof initialOnClick === 'function') {
    initialOnClick();
  }

  observer.publish('grappler-track', {
    eventName: 'FAQClicked',
    fullSchemaName: 'faq_component.FAQClicked',
    message: {
      common_properties: {
        culture_settings: {
          country: market,
          currency,
          locale,
        },
        page_type: constants[pageType],
        platform: deviceType || constants.UNSET_PLATFORM,
      },
      faq_id: id,
    },
    isMiniEvent: true,
    consentRequired: true,
    microsite: {
      name: namespace,
    },
  });

  observer.publish('tracking-event', {
    ga: [
      {
        category: `react-faq`,
        action: 'clicked',
        label: id,
      },
    ],
  });

  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.FAQS,
    component_action: COMPONENT_ACTION.FAQS.FAQ_CLICKED,
    faq: {
      id,
      question,
      answer,
      category,
    },
  });
};

const onAnswerClick = (e: Event) => {
  const target = e.target as HTMLLinkElement;
  if (target.href) {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.FAQS,
      component_action: COMPONENT_ACTION.FAQS.FAQ_URL_CLICKED,
      link_info: {
        url: target.href,
        content: target.textContent,
      },
    });
    // Write source_page.source_component to session storage after component was clicked.
    // This source_component is used on dayView page to log a Mini-Event,
    // that help analyzing the enrichment of Landing page components.
    // https://confluence.skyscannertools.net/display/CHS/Conversion+Rate+%3A+From+Landing+Page+To+Search+Result+Page
    setCarHireSeoEventValue(
      'source_component',
      CAR_HIRE_SEO_EVENT_COMPONENT.FAQS,
      'source_page',
    );
  }
};

const QuestionAndAnswer = ({
  answer,
  category,
  expanded = false,
  id,
  onClick = undefined,
  question,
  ...trackingContext
}: QuestionAndAnswerProps) => (
  <StatefulAccordionItem
    className={STYLES.QuestionAndAnswer}
    initiallyExpanded={expanded}
    id={`${id}_question`}
    onClick={() =>
      extendedOnClick(onClick, id, question, answer, category, trackingContext)
    }
    tagName="h3"
    textStyle={TEXT_STYLES.heading4}
    title={question}
  >
    <BpkText onClick={(e: Event) => onAnswerClick(e)}>
      <div dangerouslySetInnerHTML={{ __html: answer }} />
    </BpkText>
  </StatefulAccordionItem>
);

export default QuestionAndAnswer;
